export const formatPhone = (phoneNumberString: string) => {
  const match = phoneNumberString
    .replace(/\D/g, "")
    .match(/^(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }

  return null
}
